import "./Home.css";

export default function IntroText() {
  return (
    <div className="text-container">
      <p className="header">
        {" "}
        Pensano Developers: a volunteer team of developers, devOps, product
        managers, DEIA specialists, and designers on a mission to create
        positive impact through purposeful development.
      </p>
      <p className="cyan-line"></p>
      <p className="intro-text">
        {" "}
        Pensano means "they think" in Italian, and that’s exactly what we do. We
        are problem solvers who think outside the box and approach challenges
        with intention and creativity.
      </p>
      <p className="intro-text">
        {" "}
        Our team is skilled in working with agile methodologies and quickly
        adapting to new technologies. We believe in the transformative power of
        technology and actively seek opportunities to collaborate with
        like-minded organizations to create a better world.
      </p>
      <p className="intro-text">
        {" "}
        This site is a showcase of our work and our passion for using technology
        to make a difference. Take a look around and discover what we do!
      </p>
      <br></br>
    </div>
  );
}
